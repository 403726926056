<template>
  <div id="roles-create">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-container style="overflow: hidden">
          <v-card-title></v-card-title>
          <v-card-text>
            <p></p>
            <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
              <b-spinner
                variant="primary"
                type="grow"
                label="Loading..."
              ></b-spinner>
            </div>
            <v-form
              ref="form1"
              v-model="valid1"
              lazy-validation
              v-on:submit.prevent="submitForm"
            >
              <v-container>
                <v-row wrap>
                  <v-col cols="12" md="12">
                    <p>
                      <span class="text-danger">*</span> indicates required
                      field
                    </p>
                  </v-col>
                  <v-col cols="6" md="3">
                    <h6 class="text-dark">
                      <span class="text-danger">* </span>Year
                    </h6>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :items="JciYearCodeOptions"
                      :rules="JciYearCodeRules"
                      :loading="JciYearCodeOptionsLoading"
                      v-model="JciYearCode"
                      hint="Select Year"
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Training Event Title
                      </h6>
                    </label>
                    <v-text-field
                      v-model="Title"
                      :rules="TrainingEventTitleRules"
                      placeholder="Training Event Title"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Training Event point
                      </h6>
                    </label>
                    <v-text-field
                      v-model="Points"
                      :rules="TrainingEventpointRules"
                      placeholder="Training Event point"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Training Event Unit
                      </h6>
                    </label>
                    <v-text-field
                      v-model="Unit"
                      :rules="TrainingEventUnitRules"
                      placeholder="Training Event Unit"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>Training Event Unit Name</h6>
                    </label>
                    <v-autocomplete
                      v-model="UnitName"
                      label="Unit Name"
                      :items="[
                        'Per Hour',
                        'Per Event/Per Day',
                        'Per Event',
                        'Each Conference',
                        'Per program',
                        'Per Approved Program',
                      ]"
                      :rules="TrainingEventUnitNameRules"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> Training Event Max
                        Count
                      </h6>
                    </label>
                    <v-text-field
                      v-model="MaxCount"
                      :rules="TrainingEventMaxCountRules"
                      placeholder="Training Event Max Count"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-switch
                      v-model="Status"
                      :rules="TrainingEventStatusRules"
                      label="Status"
                      class="mt-5"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <v-btn
                      :disabled="!valid1"
                      @click.prevent="submitForm"
                      :loading="SubmitFlag"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#1db954"
                      class="font-size-h6 px-10 mr-3 white--text"
                      >Submit</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-container>
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    pageTitle: {
      type: String,
    },
    pageDescription: {
      type: String,
    },
    record: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      ResultFlag: false,
      SubmitFlag: false,
      row: {},
      PageTitle: "Training Event",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",

      Title: "",
      TrainingEventTitleRules: [(v) => !!v || "Title is required"],

      Points: "",
      TrainingEventpointRules: [(v) => !!v || "Point is required"],

      Unit: "",
      TrainingEventUnitRules: [(v) => !!v || "Unit is required"],

      MaxCount: "",
      TrainingEventMaxCountRules: [(v) => !!v || "Max Count is required"],

      UnitName: "",
      TrainingEventUnitNameRules: [
        (v) => !!v || "Training Event Unit Name is required",
      ],

      Status: false,
      TrainingEventStatusRules: [(v) => !!v || "Status is required"],

      JciYearCode: "",
      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,
    };
  },

  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;

        console.log("recordData=" + JSON.stringify(this.recordData));
      }
      this.pageData();
      this.getEventDetails();
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.StartupLoadingFlag = false;
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    pageData() {
      console.log("pageData called");
      this.StartupLoadingFlag = true;
      this.getJciYearCodeOptions();
    },
    getJciYearCodeOptions() {
      this.LoadingFlag = true;
      this.JciYearCodeOptionsLoading = true;
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },

    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      this.closePrompt();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog", this.ResultFlag);
    },
    refreshPageData() {
      this.pageData();
      this.getEventDetails();
      console.log("refreshPageData called");
      var record = this.record;
      console.log({ record });
      this.row = record;
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "Training Events",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },

    confirmAlert() {
      console.log("confirmAlert called");
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        /*
        var MemberTypeId = this.MemberTypeId;
        console.log("MemberTypeId=" + MemberTypeId);

        // Get Title details

        var TitleId = this.TitleCode;
        var TitleIndex = this.TitleCodeOptions.map((e) => e.value).indexOf(
          TitleId
        );
        console.log("TitleId=" + TitleId + ", TitleIndex=" + TitleIndex);
        var TitleName = this.TitleCodeOptions[TitleIndex].text;
        console.log("TitleName=" + TitleName);
        */
        var htmlTxt = "";
        // htmlTxt += "<br/> Title <b> " + TitleName + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      var EventId = this.record.TrainingEventId;
      console.log("EventId=" + EventId);
      if (validate1) {
        var upload = {
          UserInterface: 1,
          TrainingEventId: EventId,
          Year: this.JciYearCode,
          Title: this.Title,
          Points: this.Points,
          Unit: this.Unit,
          UnitName: this.UnitName,
          MaxCount: this.MaxCount,
          Status: this.Status,
        };
        console.log({ upload });

        this.SubmitFlag = true;
        this.ResultFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/training-event/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    },

    getEventDetails() {
      console.log("getEventDetails is called");

      var EventId = this.record.TrainingEventId;
      console.log("EventId=" + EventId);

      if (EventId != "") {
        var upload = {
          UserInterface: 1,
          TrainingEventId: EventId,
        };
        console.log({ upload });

        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/training-event/show";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;
            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            console.log("records=" + records);

            if (flag == 1) {
              thisIns.JciYearCode = records.Year;
              thisIns.Title = records.Title;
              thisIns.Points = records.Points;
              thisIns.Unit = records.Unit;
              thisIns.UnitName = records.UnitName;
              thisIns.MaxCount = records.Maxcount;
              thisIns.Status = records.status;
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "";
        if (EventId == "") {
          message += "City code should not be empty.";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#add-event-faculty {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>